<template>
    <div class="app-header">
        <div
            class="
                header-wrap
                d-flex
                align-center
                px-4
                text-white
                p-relative
                mb-50
            "
        >
            <router-link :to="{ name: 'Home' }" class="header-logo mr-4">
                <img src="/img/logo_light.png" alt="Logo" class="d-block" />
            </router-link>
            <nav class="header-navigation f-fill mx-4">
                <ul class="d-flex fontAh" ref="listParent">
                    <li class="header-navigation__list active transition">
                        <div
                            class="
                                link-home
                                py-2
                                px-4
                                header-navigation__anchor
                                pointer
                                text-white
                                transition
                                text-uppercase
                            "
                        >
                            <span class="text-color-s">
                                <router-link :to="{ name: 'Home' }">
                                    <i
                                        class="
                                            deshboard
                                            header-icon
                                            d-block
                                            mb-1
                                            text-center
                                        "
                                    ></i>
                                    <span class="font_12"> {{ $t("message.desktop") }}</span>
                                </router-link>
                            </span>
                        </div>
                    </li>

                    <li
                        class="header-navigation__list transition"
                        v-can-or="[
                            'boards.index',
                            'deals.index',
                            'deals.archivedDeals',
                            'deals.deletedDeals',
                            'dealTypes.index',
                            'reasons.index',
                        ]"
                    >
                        <div
                            class="
                                header-navigation__anchor
                                pointer
                                px-4
                                py-2
                                text-white
                                transition
                                text-uppercase
                            "
                        >
                            <i
                                class="
                                    transactions
                                    header-icon
                                    d-block
                                    mb-1
                                    text-center text-color-s
                                "
                            ></i>
                            <span class="text-color-s font_12">
                                {{ $t("message.deals") }}</span
                            >
                        </div>

                        <div
                            class="
                                header-navigation__list--dropdown
                                d-flex
                                p-absolute
                                w-100
                                transition
                                px-4
                            "
                        >
                            <router-link
                                :to="{ name: 'deals' }"
                                v-can="'boards.index'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{
                                    $t("message.deals")
                                }}</span>
                            </router-link>

                            <router-link
                                :to="{ name: 'archivedDeals' }"
                                v-can="'deals.archivedDeals'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{
                                    $t("message.archivedDeals")
                                }}</span>
                            </router-link>

                            <router-link
                                :to="{ name: 'deletedDeals' }"
                                v-can="'deals.deletedDeals'"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{
                                    $t("message.deletedDeals")
                                }}</span>
                            </router-link>

                            <router-link
                                v-can="'dealTypes.index'"
                                :to="{ name: 'dealTypes' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.dealTypes") }}
                                </span>
                            </router-link>

                            <router-link
                                v-can="'reasons.index'"
                                :to="{ name: 'reasons' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.deal_reasons") }}
                                </span>
                            </router-link>
                        </div>
                    </li>

                    <li
                        class="header-navigation__list transition"
                        v-can-or="['currencies.index']"
                    >
                        <div
                            class="
                                header-navigation__anchor
                                pointer
                                px-4
                                py-2
                                text-white
                                transition
                                text-uppercase
                            "
                        >
                            <i
                                class="
                                    transactions
                                    header-icon
                                    d-block
                                    mb-1
                                    text-center text-color-s
                                "
                            ></i>
                            <span class="text-color-s font_12">{{ $t("message.cash_box") }}</span>
                        </div>

                        <div
                            class="
                                header-navigation__list--dropdown
                                d-flex
                                p-absolute
                                w-100
                                transition
                                px-4
                            "
                        >
                            <router-link
                                :to="{ name: 'payments' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block"> {{ $t("message.payments") }}</span>
                            </router-link>

                            <router-link
                                :to="{ name: 'costTransactions' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block"> {{ $t("message.payments_for_expenses") }}</span>
                            </router-link>

                            <router-link
                                v-can="'currencies.index'"
                                :to="{ name: 'currency' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block"
                                    >{{ $t("message.currencies") }}
                                </span>
                            </router-link>
                        </div>
                    </li>

                    <li
                        class="header-navigation__list transition"
                        v-can-or="[
                            'taskBoards.index',
                            'tasks.archivedTasks',
                            'tasks.deletedTasks',
                            'taskReasons.index',
                        ]"
                    >
                        <div
                            class="
                                header-navigation__anchor
                                pointer
                                px-4
                                py-2
                                text-white
                                transition
                                text-uppercase
                            "
                        >
                            <i
                                class="
                                    taskss
                                    header-icon
                                    d-block
                                    mb-1
                                    text-center text-color-s
                                "
                            ></i>
                            <span class="text-color-s font_12">{{ $t("message.tasks") }}</span>
                        </div>

                        <div
                            class="
                                header-navigation__list--dropdown
                                d-flex
                                p-absolute
                                w-100
                                transition
                                px-4
                            "
                        >
                            <router-link
                                v-can="'taskBoards.index'"
                                :to="{ name: 'tasks' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{ $t("message.tasks") }}</span>
                            </router-link>

                            <router-link
                                v-can="'tasks.archivedTasks'"
                                :to="{ name: 'archivedTasks' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{ $t("message.completed_tasks") }}</span>
                            </router-link>

                            <router-link
                                v-can="'tasks.deletedTasks'"
                                :to="{ name: 'deletedTasks' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{ $t("message.deletedTasks") }}</span>
                            </router-link>

                            <router-link
                                v-can="'taskReasons.index'"
                                :to="{ name: 'taskReasons' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.taskReasons") }}
                                </span>
                            </router-link>
                        </div>
                    </li>

                    <li 
                        class="header-navigation__list transition"
                        v-can-or="[
                                'sipAccounts.index',
                                'phoneContacts.index',
                                'phoneNumberForSipAccountOutsideCall.index',
                                'callHistory.index'
                            ]"
                    >
                        <div
                            class="
                                header-navigation__anchor
                                pointer
                                px-4
                                py-2
                                text-white
                                transition
                                text-uppercase
                            "
                        >
                            <i
                                class="
                                    calling
                                    header-icon
                                    d-block
                                    mb-1
                                    text-center text-color-s
                                "
                            ></i>
                            <span class="text-color-s font_12">{{ $t("message.calls") }}</span>
                        </div>

                        <div
                            class="
                                header-navigation__list--dropdown
                                d-flex
                                p-absolute
                                w-100
                                transition
                                px-4
                            "
                        >
                            <router-link
                                v-can="'callHistory.index'"
                                :to="{ name: 'callHistories' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{ $t("message.call_history") }}</span>
                            </router-link>
                            <router-link
                                v-can="'phoneContacts.index'"
                                :to="{ name: 'phoneContacts' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{ $t("message.phoneContacts") }}</span>
                            </router-link>
                            <router-link
                                v-can="'sipAccounts.index'"
                                :to="{ name: 'sipAccount' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.sipAccounts") }}
                                </span>
                            </router-link>
                            <router-link
                                v-can="'phoneNumberForSipAccountOutsideCall.index'"
                                :to="{ name: 'sipAccount' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.phoneNumberForSipAccountOutsideCall") }}
                                </span>
                            </router-link>
                        </div>
                    </li>

                    <li
                        class="header-navigation__list transition"
                        v-can-or="['clients.index']"
                    >
                        <div
                            class="
                                header-navigation__anchor
                                pointer
                                px-4
                                py-2
                                text-white
                                transition
                                text-uppercase
                            "
                        >
                            <i
                                class="
                                    add-user
                                    header-icon
                                    d-block
                                    mb-1
                                    text-center text-color-s
                                "
                            ></i>
                            <span class="text-color-s font_12"
                                >{{ $t("message.counterparties") }}</span
                            >
                        </div>

                        <div
                            class="
                                header-navigation__list--dropdown
                                d-flex
                                p-absolute
                                w-100
                                transition
                                px-4
                            "
                        >
                            <router-link
                                v-can="'clients.index'"
                                :to="{ name: 'clients' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{ $t("message.client") }}</span>
                            </router-link>
                            <!-- <router-link
                                :to="{ name: 'contract' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">Договора клиентов</span>
                            </router-link> -->
                        </div>
                    </li>

                    <li
                        class="header-navigation__list transition"
                        v-can-or="[
                            'users.index',
                            'roles.index',
                            'permissions.index',
                        ]"
                    >
                        <div
                            class="
                                header-navigation__anchor
                                pointer
                                px-4
                                py-2
                                text-white
                                transition
                                text-uppercase
                            "
                        >
                            <i
                                class="
                                    add-user2
                                    header-icon
                                    d-block
                                    mb-1
                                    text-center text-color-s
                                "
                            ></i>
                            <span class="text-color-s font_12">{{ $t("message.h_r") }}</span>
                        </div>

                        <div
                            class="
                                header-navigation__list--dropdown
                                d-flex
                                p-absolute
                                w-100
                                transition
                                px-4
                            "
                        >
                            <router-link
                                v-can="'users.index'"
                                :to="{ name: 'users' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{
                                    $t("message.users")
                                }}</span>
                            </router-link>
                            <router-link
                                v-can="'roles.index'"
                                :to="{ name: 'roles' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{
                                    $t("message.roles")
                                }}</span>
                            </router-link>
                            <router-link
                                v-can="'permissions.index'"
                                :to="{ name: 'permissions' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block"
                                    >{{ $t("message.permissions") }}
                                </span>
                            </router-link>
                        </div>
                    </li>

                    <li
                        class="header-navigation__list transition"
                        v-can-or="[
                            'dealStages.index',
                            'paymentTypes.index',
                            'sources.index',
                            'costTypes.index',
                            'filials.index',
                            'countries.index',
                            'cities.index',
                            'categories.index',
                            'clientTypes.index',
									 'services.index',
                            'dealGoals.index',
                            'dealConditions.index',
                            'shipmentTerms.index',
                            'kPExampleTemplates.index',
                            'colors.index',
                            'packages.index',
                        ]"
                    >
                        <div
                            class="
                                header-navigation__anchor
                                pointer
                                px-4
                                py-2
                                text-white
                                transition
                                text-uppercase
                            "
                        >
                            <i
                                class="
                                    add-settings
                                    header-icon
                                    d-block
                                    mb-1
                                    text-center text-color-s
                                "
                            ></i>
                            <span class="text-color-s font_12">{{ $t("message.settings") }}</span>
                        </div>

                        <div
                            class="
                                header-navigation__list--dropdown
                                d-flex
                                p-absolute
                                w-100
                                transition
                                px-4
                            "
                        >

                            <router-link
                                v-can="'kPExampleTemplates.index'"
                                :to="{ name: 'kpTemplate' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.kpTemplates") }}
                                </span>
                            </router-link>

                            <router-link
                                v-can="'dealStages.index'"
                                :to="{ name: 'dealStage' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.dealStages") }}
                                </span>
                            </router-link>

                            <router-link
                                v-can="'paymentTypes.index'"
                                :to="{ name: 'paymentTypes' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">{{
                                    $t("message.paymentTypes")
                                }}</span>
                            </router-link>
                            <router-link
                                v-can="'sources.index'"
                                :to="{ name: 'sources' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.sources") }}
                                </span>
                            </router-link>
                            <router-link
                                v-can="'costTypes.index'"
                                :to="{ name: 'costType' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.costTypes") }}
                                </span>
                            </router-link>

                            <router-link
                                v-can="'filials.index'"
                                :to="{ name: 'filial' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.filial") }}
                                </span>
                            </router-link>

                            <router-link
                                v-can="'countries.index'"
                                :to="{ name: 'country' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.countries") }}
                                </span>
                            </router-link>

                            <router-link
                                v-can="'cities.index'"
                                :to="{ name: 'city' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.cities") }}
                                </span>
                            </router-link>

                            <router-link
                                v-can="'categories.index'"
                                :to="{ name: 'category' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.categories") }}
                                </span>
                            </router-link>

                            <router-link
                                v-can="'clientTypes.index'"
                                :to="{ name: 'clientTypes' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.clientTypes") }}
                                </span>
                            </router-link>

									 <router-link
                                v-can="'services.index'"
                                :to="{ name: 'services' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.services") }}
                                </span>
                            </router-link>

                            <router-link
                                v-can="'dealGoals.index'"
                                :to="{ name: 'dealGoals' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.dealGoals") }}
                                </span>
                            </router-link>
                            <router-link
                                v-can="'dealConditions.index'"
                                :to="{ name: 'dealConditions' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.dealConditions") }}
                                </span>
                            </router-link>
                            <router-link
                                v-can="'shipmentTerms.index'"
                                :to="{ name: 'shipmentTerms' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.shipmentTerms") }}
                                </span>
                            </router-link>

                            <router-link
                                v-can="'colors.index'"
                                :to="{ name: 'colors' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.colors") }}
                                </span>
                            </router-link>
                            
                            <router-link
                                v-can="'packages.index'"
                                :to="{ name: 'packages' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.packages") }}
                                </span>
                            </router-link>
                        </div>
                    </li>

                    <li
                        class="header-navigation__list transition"
                        v-can-or="[
                            'products.index',
                            'measurements.index',
                            'productSizes.index',
                            'productModels.index',
                        ]"
                    >
                        <div
                            class="
                                header-navigation__anchor
                                pointer
                                px-4
                                py-2
                                text-white
                                transition
                                text-uppercase
                            "
                        >
                            <i
                                class="
                                    add-settings
                                    header-icon
                                    d-block
                                    mb-1
                                    text-center text-color-s
                                "
                            ></i>
                            <span class="text-color-s font_12">{{ $t("message.product_settings") }}</span>
                        </div>

                        <div
                            class="
                                header-navigation__list--dropdown
                                d-flex
                                p-absolute
                                w-100
                                transition
                                px-4
                            "
                        >
                            <router-link
                                v-can="'products.index'"
                                :to="{ name: 'product' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.products") }}
                                </span>
                            </router-link>

                            <router-link
                                v-can="'measurements.index'"
                                :to="{ name: 'measurements' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.measurements") }}
                                </span>
                            </router-link>

                            <router-link
                                v-can="'productSizes.index'"
                                :to="{ name: 'productSizes' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.productSizes") }}
                                </span>
                            </router-link>

                            <router-link
                                v-can="'productModels.index'"
                                :to="{ name: 'productModels' }"
                                class="
                                    dropdown-anchor
                                    py-3
                                    px-2
                                    mr-2
                                    transition
                                "
                            >
                                <span class="d-block">
                                    {{ $t("message.productModels") }}
                                </span>
                            </router-link>
                        </div>
                    </li>

                    <li class="header-navigation__list active transition">
                        <div
                            class="
                                link-home
                                py-2
                                px-4
                                header-navigation__anchor
                                pointer
                                text-white
                                transition
                                text-uppercase
                            "
                        >
                            <span class="text-color-s">
                                <router-link :to="{ name: 'calendar' }">
                                    <i
                                        class="
                                            deshboard
                                            header-icon
                                            d-block
                                            mb-1
                                            text-center
                                        "
                                    ></i>
                                    <span class="font_12">  {{ $t("message.calendar") }}</span>
                                </router-link>
                            </span>
                        </div>
                    </li>


                </ul>
            </nav>

            <!-- <div class="header-notification">
                <i class="el-icon-bell"></i>
                <span>99</span>
            </div> -->

            <div>
                <el-dropdown class="flag">
                    <span class="el-dropdown-link">
                        <img src="/img/flag/russia.svg" alt class="flag_img" />
                        <!-- <img
                    v-if="$root.$i18n.locale == 'uz'"
                    src="/images/flag/uzbekistan.svg"
                    alt
                    class="flag_img"
                />
                <img
                    v-if="$root.$i18n.locale == 'ru'"
                    src="/images/flag/russia.svg"
                    alt
                    class="flag_img"
                />
                <img
                    v-if="$root.$i18n.locale == 'en'"
                    src="/images/flag/english.svg"
                    alt
                    class="flag_img"
                /> -->
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown" class="flag_ul">
                        <!-- <el-dropdown-item command="tr" >
                    <img
                        src="/img/flag/turkey.svg"
                        alt
                        class="flag_img flag_img2"
                    />
                    <span>Turkey</span>
                </el-dropdown-item>
                <el-dropdown-item command="en" >
                    <img
                        src="/img/flag/english.svg"
                        alt
                        class="flag_img flag_img2"
                    />
                    <span>English</span>
                </el-dropdown-item> -->
                        <el-dropdown-item command="ru">
                            <img
                                src="/img/flag/russia.svg"
                                alt
                                class="flag_img flag_img2"
                            />
                            <span>{{ $t("message.russia") }}</span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>

            <div class="user-info-header">
                <el-dropdown>
                    <span class="el-dropdown-link">
                        <img
                            :src="
                                user.avatar
                                    ? user.avatar
                                    : '/img/flag/employee.svg'
                            "
                            alt=""
                            style="border-radius: 50%"
                        />
                    </span>
                    <el-dropdown-menu slot="dropdown" class="f-info">
                        <el-dropdown-item class="my-p name-tell">
                            <div class="user-name">
                                {{ user ? user.name : "" }}
                            </div>
                            <div class="user-tel">
                                {{ user ? user.phone : "" }}
                            </div>
                        </el-dropdown-item>
                        <!-- <el-dropdown-item class="my-p">
                        <router-link :to="{ name: '/' }">
                            <i class="el-icon-user"></i>
                            Профиль
                        </router-link>
                        </el-dropdown-item> -->
                        <el-dropdown-item class="my-p">
                            <a @click.prevent="logout">
                                <i class="el-icon-lock"></i>
                                {{ $t("message.exit") }}
                            </a>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { tabSwitch } from "@/utils/tabSwitch.js";
import { removeToken } from "@/utils/auth";

export default {
    components: {},
    data() {
        return {
            staffOnOff: false,
        };
    },
    mounted() {
        const { listParent } = this.$refs;
        tabSwitch(listParent);
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
            slug: "auth/slug",
        }),
    },
    methods: {
        logout() {
            // if(!confirm(`Chiqishni xoxlaysizmi ?`)) {
            //     return;
            // }
            removeToken();
            window.location.reload();
        },
    },
};
</script>


<style lang="scss">
.app-header {
    position: relative;
    z-index: 2;
}

.link-home {
    min-height: 50px;
    display: inline-block;
    font-size: 0.825rem;
}

.link-home a {
    color: #fff;
}

.flag {
    margin-top: 10px;
    margin-right: 10px;

    .el-dropdown-link {
        border: 1px solid #ffffff4a;
        padding: 0px 4px !important;
        border-radius: 4px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .flag_img {
        width: 30px;
    }

    .el-icon--right {
        margin-left: 3px;
        font-size: 10px;
        color: #fff;
        margin-right: -2px;
    }
}

.flag_img2 {
    width: 31px;
}

.flag_ul {
    .el-dropdown-menu__item {
        border-bottom: 1px solid #f1f5f8;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .el-dropdown-menu__item img {
        margin-right: 5px;
    }
}

.flag_ul li:nth-child(2n + 2) {
    border-bottom: none;
}

.user-info-header {
    .el-dropdown-link img {
        width: 33px;
        height: 31px;
        margin-top: 7px;
    }
}

.my-p a {
    color: #326889;
}

.name-tell {
    line-height: 25px;
    border-bottom: 1px solid #a3a3a35c;
    font-size: 13px;
}
.header-notification {
    position: relative;
    margin-right: 15px;
}
.header-notification i {
    font-size: 23px;
}
.header-notification span {
    background-color: red;
    font-size: 8px;
    /* padding: 1px; */
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1px;
    position: absolute;
    right: -9px;
    top: -3px;
}
.fontAh .dropdown-anchor {
    font-size: 0.7rem;
}
@media only screen and (max-width: 1440px) {
    .fontAh .dropdown-anchor {
        font-size: 0.6rem;
    }
    .w-100.px-4.header-navigation__list--dropdown {
        padding: 0 0px 0 5px !important;
    }
}
</style>
